import styles from "./Wishes.module.css";

export default function Wishes({ setWishes, comment, setComment }) {
  const handleComment = (e) => {
    setComment(e);
  };

  return (
    <div className={styles.wishes}>
      <div className={styles.textarea}>
        <textarea
          placeholder="Комментарий для водителя"
          required
          value={comment}
          onChange={(e) => handleComment(e.target.value)}
        />
      </div>

      <button className={styles.button} onClick={() => setWishes(false)}>
        Готово
      </button>
    </div>
  );
}
