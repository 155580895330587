import { useState } from "react";
import styles from "./Order.module.css";
import { CgRadioCheck } from "react-icons/cg";
import axios from "axios";
import { FaMoneyBillWave } from "react-icons/fa6";
import { RiBankCardFill } from "react-icons/ri";
import { IoIosOptions } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";

function Order({
  url,
  from,
  to,
  setOpen,
  setWishes,
  price,
  coordinates,
  fromAddress,
  toAddress,
  comment,
  setWaypoints,
  setRideStops,
}) {
  const [cash, setCash] = useState("true");
  function order_button() {
    const user = window.Telegram.WebApp.initDataUnsafe.user;
    axios
      .get(url + "order", {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": true,
        },
        params: {
          user,
          address: {
            from: fromAddress,
            to: toAddress,
          },
          price: price,
          payment: cash ? "CASH" : "CARD",
          comment: comment,
        },
      })
      .then((e) => {
        window.Telegram.WebApp.close();
      });
  }

  function payment(params) {
    setCash(params);
    window.Telegram.WebApp.HapticFeedback.impactOccurred("light");
  }

  function order_button_error() {
    window.Telegram.WebApp.HapticFeedback.notificationOccurred("error");
  }

  return (
    <div className={styles.order}>
      <div className={styles.address}>
        <div className={styles.from_address}>
          <CgRadioCheck className={styles.from_icon} />
          {fromAddress ? (
            <div className={styles.title} onClick={() => setOpen(true)}>
              {fromAddress.city.name +
                ", " +
                fromAddress.street.name +
                " " +
                fromAddress.house}
            </div>
          ) : (
            <div
              className={styles.title_not_active}
              onClick={() => setOpen(true)}
            >
              Откуда
            </div>
          )}
        </div>

        <div className={styles.to_address}>
          <CgRadioCheck className={styles.to_icon} />
          {toAddress.length > 0 ? (
            <div className={styles.title}>
              {toAddress.length > 1 ? (
                <div onClick={() => setRideStops(true)}>
                  {toAddress.length + " остановки"}
                </div>
              ) : (
                <div onClick={() => setOpen(true)}>
                  {toAddress[0].city.name +
                    ", " +
                    toAddress[0].street.name +
                    " " +
                    toAddress[0].house}
                </div>
              )}
            </div>
          ) : (
            <div
              className={styles.title_not_active}
              onClick={() => setOpen(true)}
            >
              Куда
            </div>
          )}
          {toAddress.length > 0 && toAddress.length < 4 ? (
            <FaPlus
              className={styles.plus}
              onClick={() => setWaypoints(true)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className={styles.tariff}>
        <div className={styles.tariff_content}>
          <div className={styles.tariff_title}>Эконом</div>
          <div className={styles.tariff_price}>
            {price ? price : <div>-</div>}₽
          </div>
        </div>
        <img
          className={styles.tariff_img}
          src="https://tc.mobile.yandex.net/static/images/40230/90604c3e-ac1a-40d5-af0c-1b04e400b360"
          alt=""
        />
      </div>

      <div className={styles.extra}>
        {cash ? (
          <div className={styles.payment} onClick={() => payment(false)}>
            <FaMoneyBillWave
              color="#fff"
              size={20}
              style={{ marginRight: 5 }}
            />
            Наличные
          </div>
        ) : (
          <div className={styles.payment} onClick={() => payment(true)}>
            <RiBankCardFill color="#fff" size={20} style={{ marginRight: 5 }} />
            Карта
          </div>
        )}
        <div className={styles.wishes} onClick={() => setWishes(true)}>
          <IoIosOptions color="#fff" size={20} style={{ marginRight: 5 }} />{" "}
          Пожелания
        </div>
      </div>

      <div className={styles.order_button}>
        {coordinates.length === 0 ? (
          <button
            className={styles.button_off}
            onClick={() => order_button_error()}
          >
            Заказать
          </button>
        ) : (
          <button className={styles.button} onClick={() => order_button()}>
            Заказать
          </button>
        )}
      </div>
    </div>
  );
}

export default Order;
