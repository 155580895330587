import { useState } from "react";
import Search from "./search/Search.jsx";
import Listbox from "./listbox/Listbox.jsx";
import styles from "./AddressSuggest.module.css";

export default function AddressSuggest({
  url,
  from,
  to,
  setFrom,
  setTo,
  setFromAddress,
  setToAddress,
}) {
  const [focusField, setFocusField] = useState("");
  const [address, setAddress] = useState([]);

  return (
    <div className={styles.address_suggest}>
      <Search
        url={url}
        from={from}
        setFrom={setFrom}
        to={to}
        setTo={setTo}
        setAddress={setAddress}
        setFocusField={setFocusField}
      />
      <Listbox
        address={address}
        setAddress={setAddress}
        setFrom={setFrom}
        setTo={setTo}
        setFromAddress={setFromAddress}
        setToAddress={setToAddress}
        focusField={focusField}
        setFocusField={setFocusField}
      />
    </div>
  );
}
