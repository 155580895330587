import { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";

import styles from "./Map.module.css";

const point_driver = new Icon({
  iconUrl: "point_taxi.png",
  iconSize: [20, 20],
});
const point_red = new Icon({ iconUrl: "point_red.svg", iconSize: [17, 17] });
const point_black = new Icon({
  iconUrl: "point_black.svg",
  iconSize: [17, 17],
});

const point_one = new Icon({
  iconUrl: "point_one.svg",
  iconSize: [27, 27],
});

const point_two = new Icon({
  iconUrl: "point_two.svg",
  iconSize: [27, 27],
});

const point_three = new Icon({
  iconUrl: "point_three.svg",
  iconSize: [27, 27],
});

const points = [point_one, point_two, point_three];

function Map({ url, coordinates, bounds, fromAddress, toAddress }) {
  const map = useRef(null);
  const [driverLocation, setDriverLocation] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(url + "driverLocation", {
          headers: {
            "Content-Type": "application/json",
            "ngrok-skip-browser-warning": true,
          },
        })
        .then((e) => {
          setDriverLocation(e.data.locations);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, [url]);

  useEffect(() => {
    if (map.current) {
      map.current.fitBounds(bounds);
    }
  }, [bounds]);

  return (
    <div>
      <MapContainer
        center={[55.4013128, 58.5977036]}
        zoom={14}
        ref={map}
        // bounds={bounds}
        className={styles.map}
        scrollWheelZoom={true}
      >
        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {coordinates?.length === 0 &&
          driverLocation.map((e, i) => (
            <Marker key={i} icon={point_driver} position={e} />
          ))}
        {coordinates?.length > 0 && (
          <Marker
            icon={point_red}
            position={[fromAddress.latitude, fromAddress.longitude]}
          />
        )}
        {coordinates?.length > 0 &&
          toAddress.map((e, i) => {
            if (i === toAddress.length - 1) {
              return (
                <Marker
                  key={i}
                  icon={point_black}
                  position={[e.latitude, e.longitude]}
                />
              );
            }
            return (
              <Marker
                key={i}
                icon={points[i]}
                position={[e.latitude, e.longitude]}
              />
            );
          })}
        <Polyline positions={coordinates} />
      </MapContainer>
    </div>
  );
}

export default Map;
