import { useRef } from "react";
import { BsChevronCompactDown } from "react-icons/bs";
import Sheet from "react-modal-sheet";
import styles from "./SheetModal.module.css";

export default function SheetModal({ children, isOpen, setOpen }) {
  const ref = useRef();

  return (
    <Sheet
      ref={ref}
      isOpen={isOpen}
      onClose={() => setOpen(false)}
      snapPoints={[-50]}
      initialSnap={0}
      mountPoint={document.querySelector(".container")}
      disableDrag={true}
    >
      <Sheet.Container>
        <Sheet.Content>
          <div className={styles.close} onClick={() => setOpen(false)}>
            <BsChevronCompactDown size={30} color="#717171" />
          </div>

          {children}
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}
