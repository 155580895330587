import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useRaisedShadow } from "./use-raised-shadow";
import { ReorderIcon } from "./icon";
import { CgMenuGridO } from "react-icons/cg";
import { AiFillCloseCircle } from "react-icons/ai";
import styles from "./Item.module.css";

const Item = ({ item, setItems, icon }) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  function startDrag(event) {
    return dragControls.start(event);
  }

  function deleteAddress() {
    setItems((items) => items.filter((i) => i.id !== item.id));
  }

  return (
    <Reorder.Item
      value={item}
      id={item.id}
      style={{ boxShadow, y }}
      dragListener={false}
      dragControls={dragControls}
      // onPointerDown={startDrag}
    >
      <div className={styles.item}>
        {icon}
        <span className={styles.name}>
          {item.city.name + ", " + item.street.name + " " + item.house}
        </span>
        <AiFillCloseCircle
          className="icon-delete"
          onClick={() => deleteAddress(item.id)}
        />
        <CgMenuGridO className="icon" onPointerDown={startDrag} />
      </div>
    </Reorder.Item>
  );
};

export default Item;
