import Sheet from "react-modal-sheet";
import React, { useState, useRef, useEffect } from "react";
import Map from "./map/Map";
import Order from "./order/Order";
import axios from "axios";

// import styles from "./Home.module.css";
import AddressSuggest from "./addressSuggest/AddressSuggest";
import Wishes from "./wishes/Wishes";
import SheetModal from "../../ui/SheetModal";
import Waypoints from "./waypoints/Waypoints";
import RideStops from "./rideStops/RideStops";

function Home() {
  const url = "https://wezzy.ru/api/";
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const [fromAddress, setFromAddress] = useState(null);
  const [toAddress, setToAddress] = useState([]);

  const [coordinates, setCoordinates] = useState([]);
  const [, setBbox] = useState([]);

  const [isOpen, setOpen] = useState(false);
  const [wishes, setWishes] = useState(false);
  const [waypoints, setWaypoints] = useState(false);
  const [rideStops, setRideStops] = useState(false);
  const ref = useRef();

  const [bounds, setBounds] = useState([
    [55.4013128, 58.5977036],
    [55.4013128, 58.5977036],
  ]);

  const [price, setPrice] = useState(0);
  const [comment, setComment] = useState("");

  useEffect(() => {
    window.Telegram.WebApp.expand();
    window.Telegram.WebApp.onEvent("viewportChanged", () => {
      if (!window.Telegram.WebApp.isExpanded) {
        window.Telegram.WebApp.expand();
      }
    });
    window.Telegram.WebApp.enableClosingConfirmation();

    if (!fromAddress || toAddress.length === 0) {
      return setCoordinates([]);
    }
    axios
      .post(
        `https://graphhopper.com/api/1/route?key=0bfacc77-1dc8-4ccf-8fc1-71eaedee782f`,
        JSON.stringify({
          points: [
            [fromAddress.longitude, fromAddress.latitude],
            ...toAddress.map((e) => {
              return [e.longitude, e.latitude];
            }),
          ],
          snap_preventions: ["motorway", "ferry", "tunnel"],
          details: ["road_class", "surface"],
          profile: "car",
          locale: "en",
          instructions: true,
          calc_points: true,
          points_encoded: false,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((e) => {
        const data = e.data.paths[0].points.coordinates;
        const coordinatesF = data.map((e) => {
          return [e[1], e[0]];
        });

        setPrice(
          Math.ceil(Math.ceil(e.data.paths[0].distance / 1000) * 25 + 25)
        );
        // setBounds([coordinatesF[0], coordinatesF[coordinatesF.length - 1]]);
        setBounds([
          coordinatesF[0],
          ...toAddress.map((e) => [e.latitude, e.longitude]),
        ]);
        setCoordinates(coordinatesF);
        setBbox(e.data.paths[0].points.bbox);
        setOpen(false);
      })
      .catch((e) => {
        console.log(e);
      });
    window.Telegram.WebApp.ready();
  }, [fromAddress, toAddress]);

  return (
    <div className="container">
      <Map
        url={url}
        coordinates={coordinates}
        bounds={bounds}
        fromAddress={fromAddress}
        toAddress={toAddress}
      />

      <Sheet
        ref={ref}
        isOpen={true}
        onClose={() => setOpen(false)}
        // snapPoints={[250]}
        initialSnap={0}
        mountPoint={document.querySelector(".container")}
        detent="content-height"
        disableDrag={true}
      >
        <Sheet.Container>
          <Sheet.Content>
            <Order
              url={url}
              from={from}
              to={to}
              price={price}
              coordinates={coordinates}
              fromAddress={fromAddress}
              toAddress={toAddress}
              setOpen={setOpen}
              setWishes={setWishes}
              comment={comment}
              setWaypoints={setWaypoints}
              setRideStops={setRideStops}
            />
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>

      <SheetModal isOpen={isOpen} setOpen={setOpen}>
        <AddressSuggest
          url={url}
          from={from}
          to={to}
          setFrom={setFrom}
          setTo={setTo}
          setFromAddress={setFromAddress}
          setToAddress={setToAddress}
        />
      </SheetModal>

      <SheetModal isOpen={wishes} setOpen={setWishes}>
        <Wishes
          setWishes={setWishes}
          comment={comment}
          setComment={setComment}
        />
      </SheetModal>

      <SheetModal isOpen={waypoints} setOpen={setWaypoints}>
        <Waypoints
          url={url}
          setToAddress={setToAddress}
          setWaypoints={setWaypoints}
        />
      </SheetModal>

      <SheetModal isOpen={rideStops} setOpen={setRideStops}>
        <RideStops
          toAddress={toAddress}
          setToAddress={setToAddress}
          setRideStops={setRideStops}
        />
      </SheetModal>
    </div>
  );
}

export default Home;
