import styles from "./RideStops.module.css";
import { useEffect, useState } from "react";
import { Reorder } from "framer-motion";
import Item from "./item/Item.jsx";

import { Bs1CircleFill } from "react-icons/bs";
import { Bs2CircleFill } from "react-icons/bs";
import { Bs3CircleFill } from "react-icons/bs";
import { CgRadioCheck } from "react-icons/cg";

const point = [<Bs1CircleFill />, <Bs2CircleFill />, <Bs3CircleFill />];

export default function RideStops({ toAddress, setToAddress, setRideStops }) {
  const [items, setItems] = useState(toAddress);

  function updateAddress() {
    setToAddress(items);
    setRideStops(false);
  }

  return (
    <div className={styles.ride_stops}>
      <Reorder.Group axis="y" onReorder={setItems} values={items}>
        {items.map((item, i) => {
          return (
            <Item
              key={item.id}
              item={item}
              setItems={setItems}
              icon={
                items.length - 1 === i ? (
                  <CgRadioCheck className={styles.point_icon} />
                ) : (
                  point[i]
                )
              }
            />
          );
        })}
      </Reorder.Group>
      <button className={styles.button} onClick={() => updateAddress()}>
        Готово
      </button>
    </div>
  );
}
