import styles from "./Listbox.module.css";

export default function Listbox({
  address,
  setAddress,
  setFrom,
  setTo,
  setFromAddress,
  setToAddress,
  focusField,
  setFocusField,
}) {
  const addressUpdate = (e) => {
    if (focusField === "From") {
      setFrom(e.city.name + ", " + e.street.name + " " + e.house);
      setFromAddress(e);
    } else if (focusField === "To") {
      setTo(e.city.name + ", " + e.street.name + " " + e.house);
      setToAddress((params) => [...params, e]);
    }
    setAddress([]);
    setFocusField("");
  };

  return (
    <div className={styles.listbox}>
      <div className={styles.address_list}>
        {address.map((e) => (
          <div
            className={styles.address_item}
            key={e.id}
            onClick={() => addressUpdate(e)}
          >
            {e.street.name + " " + e.house}
          </div>
        ))}
      </div>
    </div>
  );
}
