import { CgRadioCheck } from "react-icons/cg";
import styles from "./Waypoints.module.css";
import { useState } from "react";
import axios from "axios";

export default function Waypoints({ url, setToAddress, setWaypoints }) {
  const [value, setValue] = useState("");
  const [address, setAddress] = useState([]);

  const handleSubmit = (e) => {
    axios
      .get(url + "address?name=" + e, {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": true,
        },
      })
      .then((e) => setAddress(e.data.data));

    setValue(e);
  };

  const addressUpdate = (e) => {
    setValue(e.city.name + ", " + e.street.name + " " + e.house);
    setToAddress((params) => [...params, e]);
    setAddress([]);
    setWaypoints(false);
  };

  return (
    <div className={styles.waypoints}>
      <div className={styles.address}>
        <div className={styles.to_address}>
          <CgRadioCheck className={styles.to_icon} />
          <input
            placeholder="Адрес места назначения"
            value={value}
            onChange={(e) => handleSubmit(e.target.value)}
          />
        </div>
      </div>

      <div className={styles.listbox}>
        <div className={styles.address_list}>
          {address.map((e) => (
            <div
              className={styles.address_item}
              key={e.id}
              onClick={() => addressUpdate(e)}
            >
              {e.street.name + " " + e.house}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
