import { CgRadioCheck } from "react-icons/cg";
import styles from "./Search.module.css";
import axios from "axios";

export default function Search({
  url,
  from,
  to,
  setAddress,
  setFocusField,
  setFrom,
  setTo,
}) {
  const handleSubmitFrom = (e) => {
    axios
      .get(url + "address?name=" + e, {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": true,
        },
      })
      .then((e) => setAddress(e.data.data));

    setFrom(e);
    setFocusField("From");
  };

  const handleSubmitTo = (e) => {
    axios
      .get(url + "address?name=" + e, {
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": true,
        },
      })
      .then((e) => setAddress(e.data.data));

    setTo(e);
    setFocusField("To");
  };

  return (
    <div className={styles.search}>
      <div className={styles.address}>
        <div className={styles.from_address}>
          <CgRadioCheck className={styles.from_icon} />
          <input
            className={styles.from}
            placeholder="Откуда"
            value={from}
            onChange={(e) => handleSubmitFrom(e.target.value)}
          />
        </div>

        <div className={styles.to_address}>
          <CgRadioCheck className={styles.to_icon} />
          <input
            placeholder="Куда"
            value={to}
            onChange={(e) => handleSubmitTo(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}
